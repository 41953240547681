import Dexie from 'dexie'

export default class OfflineDB extends Dexie {
  constructor() {
    super('tallyaid')
    this.version(2).stores({
      staff: 'id, name, phone, user_id, branch_id, branch_name, sale_region_id',
      items: '&id, name, purchase_price, sale_price, unit_id, pricing',
      report_sale_item: 'item_id, sku, item_name, total_quantity, unit_name, price, total_amount',
    })
    this.items = this.table('items')
    this.staff = this.table('staff')
  }
}
